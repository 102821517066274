import React, { Component } from "react";
import "./RNGDataPage.css";
import Promise from "promise-polyfill";
import "isomorphic-fetch";

if (!window.Promise) {
    window.Promise = Promise;
}

class RNGDataPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: props.url,
            rngData: 0,
            numberCSS: "RNG-hidden"
        };
        this.getRNGData_Wrapper = this.getRNGData_Wrapper.bind(this);
        this.getRNGData = this.getRNGData.bind(this);
    }

    componentDidMount() {
        this.getRNGData_Wrapper();
    }

    getRNGData_Wrapper() {
        var result = this.getRNGData();
        result.then((data) => {
            console.log("rng count = " + data.body);
            if (data.body > -1) {
                this.setState({
                    rngData: data.body,
                    numberCSS: "" // Update to show the number
                });
            }
            setTimeout(() => { this.getRNGData_Wrapper() }, 10000);
        });
    }

    async getRNGData() {
        const response = await fetch(this.state.url + "/Matters/getRNGData", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: null
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 ? await response.json() : ""
        };
        return responseObject;
    }

    render() {
        return (
            <div className="RNG-div">
                <h1 className={this.state.numberCSS + " RNGNumber"}>
                    {this.state.rngData}
                </h1>
            </div>
        );
    }
}

export default RNGDataPage;