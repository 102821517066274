import "babel-polyfill";
import "react-app-polyfill/ie11";
import React, { Component } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import { Button, Modal, Icon } from "semantic-ui-react";
import "./common.css";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
//import Promise from 'promise-polyfill';
import "isomorphic-fetch";

import Navbar from "./navbar";
import LoginHero from "./LoginHero";
import PasswordReset from "./PasswordReset";
import SettingsPage from "./SettingsPage";
import AdminPage from "./AdminPage";
import MattersWrapper from "./MattersWrapper";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import Notification from "./Notification";
import IQPage from "./IQPage";
import TSP from "./TSP";
import FactFindUpload from "./FactFindUpload";
import AdminIQPage from "./AdminIQPage";
import BookApptPage from "./Admin_ApptBooking";
import ApptAutoAllocatePage from "./Admin_ApptAutoAllocate";
import AgentApptsForDate from "./Admin_AgentApptsForDate";
import SchedulingSettings from "./Admin_SchedulingSettings";
import KeyUpload from "./KeyUpload";
import RNGDataPage from "./RNGDataPage";

const apiEndPoint = "LIVE";
let url = "";
switch (apiEndPoint) {
  case "LIVE":
    url = "https://api.portal.equilaw.uk.com";
    break;
  case "QA":
    url = "https://api.qaportal.equilaw.uk.com";
    break;
  default:
    url = "http://localhost:65201";
    break;
}
console.log("App.url: " + url);
if (!window.Promise) {
  window.Promise = Promise;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token:
        localStorage.token !== null &&
          localStorage.token !== "undefined" &&
          localStorage.token !== ""
          ? localStorage.token
          : "",
      loginPrivacyModalOpen: false,
      termsModalOpen: false,
      userIsClient: false,
      glossaryFileURL:
        "https://api.portal.equilaw.uk.com/portalfiles/Portal%20Terminology%202021.pdf",
      glossaryText: "Portal Terminology",
      portalVersion: "v1.762",
    };
    this.GetToken = this.GetToken.bind(this);
    this.handlePrivacyModal = this.handlePrivacyModal.bind(this);
    this.handleTermsModal = this.handleTermsModal.bind(this);
    //
    //var Promise = require('es6-promise').Promise;
    var currentUrl = window.location.href.toLowerCase();
    if (!currentUrl.includes("localhost") && !currentUrl.includes("https")) {
      currentUrl = currentUrl.replace("http", "https");
      window.location.href = currentUrl;
    }
  }

  async GetToken(username, password, url) {
    const response = await fetch(url + "/Access/Authenticate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });
    const responseObject = {
      httpCode: response.status,
      body: response.status === 200 ? await response.json() : "",
    };
    console.log("responseObject: " + responseObject);
    return responseObject;
  }
  //
  handlePrivacyModal(event) {
    this.setState({
      loginPrivacyModalOpen: !this.state.loginPrivacyModalOpen,
    });
  }
  //
  handleTermsModal(event) {
    this.setState({
      termsModalOpen: !this.state.termsModalOpen,
    });
  }
  //
  //
  render() {
    //
    return (
      <BrowserRouter>
        <div className="App ui container content">
          <Route
            exact
            path="/"
            component={(props) => (
              <LoginHero
                history={props.history}
                location={props.location}
                match={props.match}
                getToken={this.GetToken}
                url={url}
              />
            )}
          ></Route>
          <Route
            path="/passwordreset"
            component={(props) => (
              <div>
                <br />
                <br />
                <PasswordReset
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  url={url}
                />
              </div>
            )}
          ></Route>
          <Route
            path="/iq"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <IQPage history={props.history} url={url} />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/matters"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <Notification url={url} />
                <br />
                <MattersWrapper history={props.history} url={url} />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/settings"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <SettingsPage
                  history={props.history}
                  location={props.location}
                  url={url}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/admin"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <AdminPage
                  history={props.history}
                  url={url}
                  subPage="createUser"
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/editUser"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <AdminPage
                  history={props.history}
                  url={url}
                  subPage="editUser"
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/createcompany"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <AdminPage
                  history={props.history}
                  url={url}
                  subPage="createCompany"
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/mapsources"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <AdminPage
                  history={props.history}
                  url={url}
                  subPage="mapSources"
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/tsp"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                {/* <TSP history={props.history} url={url} subPage="tsp" /> */}
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/newMatter"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <FactFindUpload
                  history={props.history}
                  url={url}
                  subPage="responsible"
                  apiEndPoint={apiEndPoint}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/keyUpload"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <KeyUpload
                  history={props.history}
                  url={url}
                  subPage="responsible"
                  apiEndPoint={apiEndPoint}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/adminIQ"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <AdminIQPage
                  history={props.history}
                  url={url}
                  subPage="adminIQ"
                  apiEndPoint={apiEndPoint}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/agentCoverage"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <AdminPage
                  history={props.history}
                  url={url}
                  subPage="adminAgentCoverage"
                  apiEndPoint={apiEndPoint}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/agentCoveragev2"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <AdminPage
                  history={props.history}
                  url={url}
                  subPage="adminAgentCoveragev2"
                  apiEndPoint={apiEndPoint}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/agentAppts"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <AdminPage
                  history={props.history}
                  url={url}
                  subPage="adminAgentAppts"
                  apiEndPoint={apiEndPoint}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/bookAppts"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <BookApptPage
                  history={props.history}
                  url={url}
                  subPage="adminBookAgentAppts"
                  apiEndPoint={apiEndPoint}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/apptAutoAllocate"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <ApptAutoAllocatePage
                  history={props.history}
                  url={url}
                  subPage="adminApptAutoAllocate"
                  apiEndPoint={apiEndPoint}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/agentOverview"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <AgentApptsForDate
                  history={props.history}
                  url={url}
                  subPage="agentOverview"
                  apiEndPoint={apiEndPoint}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/schedulingSettings"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <AdminPage
                  history={props.history}
                  url={url}
                  subPage="schedulingSettings"
                  apiEndPoint={apiEndPoint}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/novexMigration"
            component={(props) => (
              <div>
                <Navbar
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  items={[
                    {
                      key: "item_2",
                      id: "2",
                      name: "matters",
                      text: "Your Cases",
                    },
                    {
                      key: "item_3",
                      id: "3",
                      name: "settings",
                      text: "Settings",
                    },
                    {
                      key: "item_4",
                      id: "4",
                      name: "admin",
                      text: "Admin",
                      isDropdown: true,
                    },
                    {
                      key: "item_5",
                      id: "5",
                      name: "newMatter",
                      text: "New Matter",
                    },
                    {
                      key: "item_99",
                      id: "99",
                      name: "logout",
                      text: "Log Out",
                      position: "right",
                    },
                  ]}
                  colour="Navbar"
                  style={{ display: "none;" }}
                  url={url}
                />
                <br />
                <br />
                <AdminPage
                  history={props.history}
                  url={url}
                  subPage="novexMigration"
                  apiEndPoint={apiEndPoint}
                />
                <br />
                <br />
                <button
                  className="button-as-link"
                  onClick={this.handlePrivacyModal}
                >
                  Privacy Policy
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  onClick={this.handleTermsModal}
                >
                  Terms
                </button>
                &emsp;|&emsp;
                <button
                  className="button-as-link"
                  type="submit"
                  onClick={() =>
                    window.location.replace(this.state.glossaryFileURL)
                  }
                >
                  {this.state.glossaryText}
                </button>
                &emsp;|&emsp;
                {this.state.portalVersion}
              </div>
            )}
          ></Route>
          <Route
            path="/rngData"
            component={(props) => (
              <div>
                <br />
                <br />
                <RNGDataPage
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  url={url}
                />
              </div>
            )}
          ></Route>
          <br />
          <br />
          <Modal
            style={{ position: "static" }}
            open={this.state.loginPrivacyModalOpen}
            closeOnDimmerClick={true}
            onClose={() => this.setState({ loginPrivacyModalOpen: false })}
          >
            <Modal.Content>
              <PrivacyPolicy />
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="green"
                onClick={() => this.setState({ loginPrivacyModalOpen: false })}
              >
                <Icon name="checkmark" />
                &nbsp;Ok
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            style={{ position: "static" }}
            open={this.state.termsModalOpen}
            closeOnDimmerClick={true}
            onClose={() => this.setState({ termsModalOpen: false })}
          >
            <Modal.Content>
              <Terms />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.setState({ termsModalOpen: false })}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
